export const environment = {
  name: 'DEV',
  production: true,
  traceSession: false,
  kcRealmUrl: 'https://authdev.wastics.eu/',
  kcRealm: 'wastics',
  apiUrl: 'https://awk-api.wastics.eu/awk-service/api/v1',
  awkExcelApi: 'https://excel9vb78nuh.wastics.eu/v1/data',
  puppeteerApiUrl: 'https://pdff7qz5ptu.wastics.eu',
  quarkusApiUrl: 'https://api-peru.wastics.eu/v1',
  matchMakingApiUrl: 'https://match-api.wastics.eu',
  notifications: {
    publicKey:
      'BEDs6HyiX9drmCFaqDhve0jRv-pMO8mTbquhGT0OJo0t7jRX9QXXXJiXXRv54tUaOMTf_yxQ113gkzG6lk9FYf4',
  },
};
